"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
require("element-ui/lib/theme-chalk/display.css");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _zhTW = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-TW"));
var _locale = _interopRequireDefault(require("element-ui/lib/locale"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getStore, setStore } from '@/utils/store'
// import i18n from '@/i18n'
var _default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default
  },
  data() {
    return {
      store: null
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)(['user', 'name', 'sidebar', 'avatar']),
    langs: {
      get() {
        return Object.keys(this.$i18n.messages);
      }
    }
  },
  async created() {
    if (this.user.stores && this.user.stores.length === 1) {
      // this.setStore(this.user.stores[0].uuid)
    } else {
      // const store = getStore(this.user)
      // this.store = this.user.stores.find(item => item.uuid === store)
    }
  },
  methods: {
    handleLangChange(lang) {
      if (this.$i18n.locale !== lang) {
        // console.log(locale)
        this.$i18n.locale = lang;
        const dict = {
          en: _en.default,
          zh: _zhTW.default
        };
        _locale.default.use(dict[lang]);
        // console.log("Set Language: "+locale);
        _jsCookie.default.set('crm.lang', lang, {
          secure: true
        });
        // console.info('App currentRoute:', this.$router.currentRoute, this.$i18n.locale  )
        // const to = this.$router.resolve({ params: { locale }}) // <--------------------
        // this.$router.push(this.$route.path) // <--------------------

        // pos/item/edit, pos/category/edit
        /* if (false && /^\/pos\/(category|item)\/edit\//.test(this.$route.path)) {
          // console.log('try reload', this.$i18n.locale, i18n.locale)
          this.$router.push(this.$route.path + '?lang=' + this.$i18n.locale)
          // this.$router.go(0)
        } else { */
        // this.$router.go(0)
        // }
      }
    },

    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      this.$router.push(`/login`);
    }
    /* setStore(uuid) {
      const prevStore = getStore(this.user)
      if (prevStore !== uuid) {
        setStore(uuid)
        if (!prevStore || this.$router.currentRoute.path.indexOf('/pos') !== -1) {
          const editPath = this.$router.currentRoute.path.indexOf('/edit/')
          if (editPath === -1) {
            this.$router.go()
          } else {
            this.store = this.user.stores.find(item => item.uuid === uuid)
            this.$router.push(this.$route.path.substr(0, editPath))
          }
        } else {
          this.store = this.store = this.user.stores.find(item => item.uuid === uuid)
        }
      }
    } */
  }
};
exports.default = _default;