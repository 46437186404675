"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _Logo = _interopRequireDefault(require("./Logo"));
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _variables = _interopRequireDefault(require("@/styles/variables.scss"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getStore } from '@/utils/store'
// import Layout from '@/layout'
// import { getStores } from '@/api/store'
var _default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  data() {
    return {
      finalRoutes: []
      // store: null
    };
  },

  computed: {
    ...(0, _vuex.mapGetters)(['permission_routes', 'sidebar', 'user']),
    // routes() {
    //   return this.$router.options.routes
    // },
    activeMenu() {
      const route = this.$route;
      const {
        meta,
        path
      } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.sidebar.opened;
    },
    variables() {
      return _variables.default;
    },
    isCollapse() {
      return !this.sidebar.opened;
    }
  },
  async created() {
    // this.store = getStore(this.user)
    // routes.pop()
    /* const response = await getStores()
    const stores = response.data
    for (const store of stores) {
      routes.push(
        {
          path: '/storeManagement/' + store.uuid,
          redirect: '/stores',
          component: Layout,
          name: store.name,
          meta: {
            title: store.name,
            icon: 'star',
            roles: ['edit.users']
          },
          children: [
            {
              path: '/stores/roster/' + store.uuid,
              name: store.name + 'Roster',
              component: () => import('@/views/duties/roster'),
              meta: {
                title: store.name + ' Roster',
                icon: 'table'
              }
            }
            // {
            //   path: '/stores/view/' + store.uuid,
            //   name: 'Duties',
            //   component: () => import('@/views/templates/index'),
            //   meta: {
            //     title: 'Duties',
            //     icon: 'list'
            //   }
            // }
          ]
        }
      )
    }*/
    this.finalRoutes = Array.from(new Set(this.permission_routes.map(a => a.name || a))).map(name => typeof name === 'string' ? this.permission_routes.find(a => a.name === name) : name);
    this.finalRoutes = this.finalRoutes.slice(0, -1); /* .map(route => {
                                                      if (route.withStore && !this.store) {
                                                      route.hidden = true
                                                      }
                                                      return route
                                                      }) */
  }
};
exports.default = _default;